
import { $gettext, $ngettext } from 'vue-gettext';
import { ColorAlias } from '@/utils/color';
import { computed, defineComponent, ref, watch } from 'vue';
import { currentLanguage, getTranslated } from '@/translation';
import { Customer, useCustomerStore } from '@/stores/customer';
import { fileIcon, Icon } from '@/components/icon/BpIcon';
import { formatBytes } from '@/utils/string';
import { loadNewsArticles } from '@/utils/news';
import { localizeDate } from '@/utils/date';
import { OptionalKeys } from '@/utils/object';
import { Product, useVersionStore } from '@/stores/version';
import { SupportStatusId, useSupportStatusStore } from '@/stores/supportStatus';
import { useAppStore } from '@/stores/app';
import { useDownloadStore } from '@/stores/download';
import { useEditionStore } from '@/stores/edition';
import { User, useUserStore } from '@/stores/user';
import { useSalutationStore } from '@/stores/salutation';
import { getFullSalutation, useUser } from '@/utils/user';
import { useVideoStore } from '@/stores/video';
import BpVideoPreviewVue from '@/components/video/BpVideoPreview.vue';
import clone from '@sahnee/clone';
import useDarkMode from '@/compositions/use-dark-mode';

export default defineComponent({
  name: 'Home',
  components: {
    BpVideoPreview: BpVideoPreviewVue
  },
  setup() {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const appStore = useAppStore();
    const customerStore = useCustomerStore();
    const downloadStore = useDownloadStore();
    const editionStore = useEditionStore(true);
    const salutationStore = useSalutationStore();
    const supportStatusStore = useSupportStatusStore();
    const userStore = useUserStore();
    const versionStore = useVersionStore();
    const videoStore = useVideoStore();

    ///-------------------------------------------------------------------
    /// sessionUser & CUSTOMER
    ///-------------------------------------------------------------------

    const sessionUser = useUser();
    const customer = ref({} as OptionalKeys<Customer, '_id' | '_rev'>);
    const user = ref({} as OptionalKeys<User, '_id' | '_rev'>);

    watch(() => [userStore.loaded, customerStore.loaded, sessionUser.value.email, sessionUser.value.company], async () => {
      if (!sessionUser.value.email || (sessionUser.value.email === 'admin' && !sessionUser.value.roles.includes('impersonate_user') && (!userStore.loaded || !customerStore.loaded))) { return }
      const response = await customerStore.readById(sessionUser.value.company || '');
      if (!response || !response.success || !response.data) { return }
      customer.value = clone(response.data);
      const userResponse = await userStore.readByEmail(sessionUser.value.email);
      if (!userResponse || !userResponse.success || !userResponse.data) { return }
      user.value = clone(userResponse.data)
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// NEWS
    ///-------------------------------------------------------------------

    const news = ref();
    const newsLoading = ref(0);
    const newsIsLoading = computed(() => newsLoading.value > 0);

    watch(() => currentLanguage.value, async () => {
      newsLoading.value++;
      try {
        news.value = await loadNewsArticles();
      } catch (error: unknown) {
        console.error($gettext('Error while loading news: %{error}', { error: (error as Error).message }));
      } finally {
        newsLoading.value--;
      }
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// PRODUCT
    ///-------------------------------------------------------------------

    // PRODUCT STATUSES
    const productStatus = ref(new Map<string, { _id: SupportStatusId, props: { color: ColorAlias, icon: Icon, text: string } }>())
    watch(() => [customer.value, currentLanguage.value, versionStore.isLoading()], () => {
      if (!customer.value || !versionStore.loaded || versionStore.isLoading()) {
        return;
      }
      for (const product of versionStore.getRootProducts()) {
        getProductStatus(product);
      }
    }, { immediate: true });

    function getProductStatus(product: Product) {
      if (!customerStore.hasProduct(customer.value, product.uuid)) {
        productStatus.value.set(product.uuid, {
          _id: 'not_purchased',
          props: {
            color: 'red' as ColorAlias,
            icon: 'xmark',
            text: product.uuid === 'bestinformed'
              ? customer.value.subscription
                ? $gettext('You do not own a subscription.')
                : $gettext('You do not own a support & software assurance.')
              : $gettext("You do not own a license."),
          }
        });
        return;
      }
      const status = supportStatusStore.getByCustomer(customer.value as Customer, product.uuid);
      switch (status._id) {
        case 'active': {
          productStatus.value.set(product.uuid, {
            _id: status._id,
            props: {
              color: 'green' as ColorAlias,
              icon: 'check',
              text: product.uuid === 'bestinformed'
                ? customer.value.subscription
                  ? $gettext('You have a valid subscription until %{date}.', { date: localizeDate(customer.value.supportTo) })
                  : $gettext('You have a valid support & software assurance until %{date}.', { date: localizeDate(customer.value.supportTo) })
                : $gettext('You have a valid license until %{date}.', { date: localizeDate(product.uuid === 'bestproxy' ? customer.value.bestproxyruntimeto : customer.value.supportTo) }),
            }
          });
          break;
        }
        case 'canceled': {
          productStatus.value.set(product.uuid, {
            _id: status._id,
            props: {
              color: 'red' as ColorAlias,
              icon: status.icon,
              text: product.uuid === 'bestinformed'
                ? customer.value.subscription
                  ? $gettext('You have canceled the subscription.')
                  : $gettext('You have canceled the support & software assurance.')
                : $gettext('You have canceled the license.'),
            }
          });
          break;
        }
        case 'expired': {
          productStatus.value.set(product.uuid, {
            _id: status._id,
            props: {
              color: 'red' as ColorAlias,
              icon: status.icon,
              text: product.uuid === 'bestinformed'
                ? customer.value.subscription
                  ? $gettext('Your subscription has been expired on %{date}.', { date: localizeDate(customer.value.supportTo) })
                  : $gettext('Your support & software assurance has been expired on %{date}.', { date: localizeDate(customer.value.supportTo) })
                : $gettext('Your license has been expired on %{date}.', { date: localizeDate(product.uuid === 'bestproxy' ? customer.value.bestproxyruntimeto : customer.value.supportTo) }),
            }
          });
          break;
        }
        case 'expired_grace_period': {
          productStatus.value.set(product.uuid, {
            _id: status._id,
            props: {
              color: 'yellow' as ColorAlias,
              icon: status.icon,
              text: product.uuid === 'bestinformed'
                ? customer.value.subscription
                  ? $ngettext(
                      'Your subscription has expired %{days} day ago.',
                      'Your subscription has expired %{days} days ago.',
                      getExpiredTime(customer.value.supportTo),
                      { days: getExpiredTime(customer.value.supportTo) }
                    )
                  : $ngettext(
                      'Your support & software assurance has expired %{days} day ago.',
                      'Your support & software assurance has expired %{days} days ago.',
                      getExpiredTime(customer.value.supportTo),
                      { days: getExpiredTime(customer.value.supportTo) }
                    )
                : $gettext('Your license has expired %{days} days ago.', { days: getExpiredTime(product.uuid === 'bestproxy' ? customer.value.bestproxyruntimeto : customer.value.supportTo) })
            }
          });
          break;
        }
        case 'pilot_support': {
          productStatus.value.set(product.uuid, {
            _id: status._id,
              props: {
              color: 'green' as ColorAlias,
              icon: status.icon,
              text: $gettext('You have unlimited access in your testing period.'),
            }
          });
          break;
        }
        default: {
          productStatus.value.set(product.uuid, {
            _id: status._id,
            props: {
              color: 'red' as ColorAlias,
              icon: status.icon,
              text: product.uuid === 'bestinformed'
                ? customer.value.subscription
                  ? $gettext('You do not have a valid subscription.')
                  : $gettext('You do not have a valid support & software assurance.')
                : $gettext("You do not have a valid license."),
            }
          });
          break;
        }
      }
    }

    const productList = ref(['bestinformed', 'bestproxy', 'bestzero']);
    watch(() => [versionStore.isLoading(), productStatus.value], () => {
      if (!versionStore.loaded || versionStore.isLoading()) {
        return;
      }
      productList.value = versionStore.getRootProducts()
        .filter(product => productStatus.value.get(product.uuid)?._id !== 'not_purchased')
        .map(product => product.uuid)
        .sort((a, b) => a < b ? -1 : 1);
    }, { deep: true })

    // PRODUCTS
    const products = ref(new Map<string, Product>());
    watch(() => [versionStore.isLoading(), productList.value], () => {
      if (!versionStore.loaded || versionStore.isLoading()) {
        return;
      }
      for (const productId of productList.value) {
        products.value.set(productId, versionStore.findVersionByName(productId) as Product)
      }
    }, { immediate: true })

    // PRODUCTS > BESTINFORMED
    const bestinformedVersion = ref<Product>();
    watch(() => customer.value, async () => {
      if (!customer.value || Object.keys(customer.value).length === 0) {
        return;
      }
      bestinformedVersion.value = await customerStore.getBestinformedVersion(customer.value);
    }, { immediate: true })

    function getExpiredTime(expired: string): number {
      const now = new Date();
      const expiredDate = new Date(expired);

      const beginDate = new Date(Date.UTC(expiredDate.getFullYear(), expiredDate.getMonth(), expiredDate.getDate(), 0, 0));
      const endDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0));

      return (endDate.getTime() - beginDate.getTime()) / (24 * 60 * 60 * 1000);
    }

    const productLicenses = ref<[string, number][]>([])
    watch(() => customer.value, () => {
      if (!customer.value) {
        return;
      }
      productLicenses.value = [
        [$ngettext('CAL', 'CALs', customer.value.unlimited_cals ? Infinity : customer.value.numberOfLicences), customer.value.unlimited_cals ? -1 : customer.value.numberOfLicences] as [string, number],
        [$ngettext('Mobile CAL', 'Mobile CALs', customer.value.mobilecals), customer.value.mobilecals] as [string, number],
        [$ngettext('Cluster node', 'Cluster nodes', customer.value.clusternodes), customer.value.clusternodes] as [string, number],
      ].filter(([,count]) => !!count && count !== 0)
    }, { immediate: true })

    function appCount(customer: OptionalKeys<Customer, '_id' | '_rev'>): number {
      const editionApps = (editionStore.getById(customerStore.getVTigerBestinformedEdition(customer))?.apps || []).filter(app => !['starter', 'standard', 'alarm'].includes(app));
      const customerApps = customerStore.getBestinformedApps(customer).filter(app => !['starter', 'standard', 'alarm'].includes(app));
      const apps = new Set([...customerApps, ...editionApps]);
      return Math.max(apps.size, 0);
    }

    ///-------------------------------------------------------------------
    /// PLANNED WEBINAR
    ///-------------------------------------------------------------------
    
    const hasPlannedWebinar = ref(false);
    watch(() => videoStore.loaded, async () => {
      if (!videoStore.loaded) { return }
      const nextWebinar = await videoStore.loadNextWebinar();
      hasPlannedWebinar.value = !!nextWebinar;
    })

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const { current: darkMode } = useDarkMode();

    const style = computed(() => ({
      '--home-heading-color': (darkMode && darkMode.value) ? 'var(--theme-text)' : 'var(--color-cw-blue)',
    }))

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      appCount,
      appStore,
      bestinformedVersion,
      customer,
      customerStore,
      currentLanguage,
      darkMode,
      downloadStore,
      editionStore,
      fileIcon,
      formatBytes,
      getFullSalutation,
      getTranslated,
      hasPlannedWebinar,
      localizeDate,
      news,
      newsIsLoading,
      productLicenses,
      productList,
      products,
      productStatus,
      salutationStore,
      sessionUser,
      style,
      user,
      versionStore,
      videoStore,
    }
  }
});
