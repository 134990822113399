import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bp-home-view__header-wrapper" }
const _hoisted_2 = { class: "bp-home-view__greeting" }
const _hoisted_3 = {
  key: 0,
  style: {"font-weight":"900"}
}
const _hoisted_4 = {
  key: 1,
  style: {"font-weight":"900"}
}
const _hoisted_5 = { style: {"font-weight":"900"} }
const _hoisted_6 = { class: "flex-column gap-md" }
const _hoisted_7 = { class: "bp-home-view__cordaware-logo-wrapper" }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "flex-column gap-md" }
const _hoisted_12 = { class: "flex gap-sm" }
const _hoisted_13 = { class: "flex-column gap-sm" }
const _hoisted_14 = {
  key: 0,
  class: "flex gap-sm"
}
const _hoisted_15 = { class: "flex-column gap-sm" }
const _hoisted_16 = { class: "flex-column gap-sm" }
const _hoisted_17 = { class: "flex-column gap-sm" }
const _hoisted_18 = { class: "flex-column gap-md" }
const _hoisted_19 = { class: "bp-home-view__customer-number mono" }
const _hoisted_20 = { class: "flex-column gap-md" }
const _hoisted_21 = { class: "flex-column" }
const _hoisted_22 = { class: "flex-column gap-md" }
const _hoisted_23 = {
  key: 0,
  class: "m-0"
}
const _hoisted_24 = { class: "flex-column gap-md" }
const _hoisted_25 = { class: "flex-column gap-md" }
const _hoisted_26 = {
  key: 0,
  class: "m-0"
}
const _hoisted_27 = { class: "flex-column gap-md" }
const _hoisted_28 = { class: "flex-column" }
const _hoisted_29 = { class: "flex-column gap-md" }
const _hoisted_30 = { class: "flex-column" }
const _hoisted_31 = {
  key: 1,
  class: "bp-home-view__news-grid"
}
const _hoisted_32 = { class: "flex-column gap-md" }
const _hoisted_33 = ["innerHTML"]
const _hoisted_34 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_loading = _resolveComponent("bp-loading")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_bp_alert = _resolveComponent("bp-alert")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_download_preview = _resolveComponent("bp-download-preview")!
  const _component_bp_video_preview = _resolveComponent("bp-video-preview")!
  const _component_bp_masonry_grid = _resolveComponent("bp-masonry-grid")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "bp-home-view",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createVNode(_component_bp_view_header, {
      image: "/static/background/app_background_home.jpg",
      icon: ['fad','house-chimney']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_card, {
      loading: _ctx.customerStore.isLoading(),
      "fill-width": "",
      "fill-height": ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Hello")
              ])),
              _: 1
            }),
            (_ctx.user && (_ctx.user.salutation || _ctx.user.forename || _ctx.user.surname))
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getFullSalutation(_ctx.user as User)), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.sessionUser.email), 1)),
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("from")
              ])),
              _: 1
            }),
            _createElementVNode("span", null, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.customer.company), 1),
              _cache[3] || (_cache[3] = _createTextVNode("!"))
            ])
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h2", _hoisted_7, [
            _createElementVNode("img", {
              class: "bp-home-view__cordaware-logo",
              src: `/static/logo/cordaware_${_ctx.darkMode ? 'dark' : 'light'}.png`
            }, null, 8, _hoisted_8),
            _createVNode(_component_translate, { class: "bp-home-view__cordaware-logo-prefix" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Welcome to the")
              ])),
              _: 1
            }),
            _createVNode(_component_translate, { class: "bp-home-view__cordaware-logo-suffix" }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Customer portal")
              ])),
              _: 1
            })
          ]),
          (_openBlock(), _createBlock(_component_bp_masonry_grid, { key: _ctx.currentLanguage }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productList, (productId) => {
                return (_openBlock(), _createBlock(_component_bp_card, {
                  key: productId,
                  loading: _ctx.appStore.isLoading() || _ctx.editionStore.isLoading() || _ctx.versionStore.isLoading(),
                  "fill-width": ""
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("h2", null, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("License")
                        ])),
                        _: 1
                      }),
                      _cache[7] || (_cache[7] = _createTextVNode(": ")),
                      _createVNode(_component_bp_loading, {
                        "model-value": !_ctx.products.has(productId) || (productId === 'bestinformed' && !_ctx.bestinformedVersion),
                        inline: ""
                      }, null, 8, ["model-value"]),
                      (_ctx.products.has(productId) && (productId !== 'bestinformed' || !!_ctx.bestinformedVersion))
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createElementVNode("span", {
                              innerHTML: _ctx.versionStore.getName(_ctx.products.get(productId))
                            }, null, 8, _hoisted_9),
                            (productId === 'bestinformed')
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  innerHTML: ` ${_ctx.versionStore.getName(_ctx.bestinformedVersion)}`
                                }, null, 8, _hoisted_10))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                      key: _ctx.products.size,
                      to: { name: 'license' },
                      class: "ml-auto flex-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_bp_icon, { icon: ['fad', 'file-certificate'] })
                      ]),
                      _: 2
                    }, 1024)), [
                      [_directive_tooltip, `<p>${_ctx.$gettext('Show %{product} license', { product: _ctx.versionStore.getName(_ctx.products.get(productId)) })}</p>`]
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_bp_alert, _mergeProps({
                        class: "bp-home-view__product-status",
                        "border-radius": "sm",
                        small: "",
                        ref_for: true
                      }, _ctx.productStatus.get(productId)?.props), null, 16),
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_bp_icon, _mergeProps({ ref_for: true }, _ctx.productStatus.get(productId)?._id === 'active'
                ? { icon: 'check', color: 'green' }
                : _ctx.productStatus.get(productId)?._id !== 'not_purchased'
                  ? { icon: ['far', 'circle-minus'], color: 'yellow' }
                  : { icon: 'xmark', color: 'red' }), null, 16),
                        _createElementVNode("div", _hoisted_13, [
                          (_ctx.productStatus.get(productId)?._id === 'active')
                            ? (_openBlock(), _createBlock(_component_translate, {
                                key: 0,
                                tag: "p",
                                "translate-params": { product: _ctx.versionStore.getName(_ctx.products.get(productId)) },
                                "render-html": true
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode("Direct access to all %{product} versions.")
                                ])),
                                _: 2
                              }, 1032, ["translate-params"]))
                            : (_ctx.productStatus.get(productId)?._id !== 'not_purchased')
                              ? (_openBlock(), _createBlock(_component_translate, {
                                  key: 1,
                                  tag: "p",
                                  "translate-params": { product: _ctx.versionStore.getName(_ctx.products.get(productId)), date: _ctx.localizeDate(productId === 'bestproxy' ? _ctx.customer.bestproxyruntimeto : _ctx.customer.supportTo) },
                                  "render-html": true
                                }, {
                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                    _createTextVNode("Direct access to all released %{product} versions until %{date}.")
                                  ])),
                                  _: 2
                                }, 1032, ["translate-params"]))
                              : (_openBlock(), _createBlock(_component_translate, {
                                  key: 2,
                                  tag: "p",
                                  "translate-params": { product: _ctx.versionStore.getName(_ctx.products.get(productId)) },
                                  "render-html": true
                                }, {
                                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                                    _createTextVNode("No direct access to %{product} versions.")
                                  ])),
                                  _: 2
                                }, 1032, ["translate-params"]))
                        ])
                      ]),
                      (productId !== 'bestproxy')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createVNode(_component_bp_icon, _mergeProps({ ref_for: true }, _ctx.productStatus.get(productId)?._id === 'active'
                ? { icon: 'check', color: 'green' }
                : _ctx.productStatus.get(productId)?._id !== 'not_purchased'
                  ? { icon: ['far', 'circle-minus'], color: 'yellow' }
                  : { icon: 'xmark', color: 'red' }), null, 16),
                            _createElementVNode("div", _hoisted_15, [
                              (_ctx.productStatus.get(productId)?._id === 'active')
                                ? (_openBlock(), _createBlock(_component_translate, {
                                    key: 0,
                                    tag: "p",
                                    "translate-params": { product: _ctx.versionStore.getName(_ctx.products.get(productId)) },
                                    "render-html": true
                                  }, {
                                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                                      _createTextVNode("Exclusive access to all video tutorials & webinar recordings about %{product}.")
                                    ])),
                                    _: 2
                                  }, 1032, ["translate-params"]))
                                : (_ctx.productStatus.get(productId)?._id !== 'not_purchased')
                                  ? (_openBlock(), _createBlock(_component_translate, {
                                      key: 1,
                                      tag: "p",
                                      "translate-params": { product: _ctx.versionStore.getName(_ctx.products.get(productId)), date: _ctx.localizeDate(productId === 'bestproxy' ? _ctx.customer.bestproxyruntimeto : _ctx.customer.supportTo) },
                                      "render-html": true
                                    }, {
                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode("Exclusive access to all released video tutorials & webinar recordings about %{product} until %{date}.")
                                      ])),
                                      _: 2
                                    }, 1032, ["translate-params"]))
                                  : (_openBlock(), _createBlock(_component_translate, {
                                      key: 2,
                                      tag: "p",
                                      "translate-params": { product: _ctx.versionStore.getName(_ctx.products.get(productId)), date: _ctx.localizeDate(productId === 'bestproxy' ? _ctx.customer.bestproxyruntimeto : _ctx.customer.supportTo) },
                                      "render-html": true
                                    }, {
                                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                                        _createTextVNode("No exclusive access to video tutorials & webinar recordings about %{product}.")
                                      ])),
                                      _: 2
                                    }, 1032, ["translate-params"]))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.productStatus.get(productId)?._id === 'active')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _cache[18] || (_cache[18] = _createElementVNode("hr", { style: {"margin":"0"} }, null, -1)),
                            (productId === 'bestinformed')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createVNode(_component_bp_label, {
                                    seamless: "",
                                    "label-width": "38.2%",
                                    label: _ctx.$gettext('Edition')
                                  }, {
                                    default: _withCtx(() => [
                                      (_ctx.editionStore.loaded)
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createTextVNode(_toDisplayString(_ctx.customerStore.getBestinformedEditionName(_ctx.customer)), 1)
                                          ], 64))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }, 8, ["label"]),
                                  (!_ctx.bestinformedVersion?.uuid.includes('5'))
                                    ? (_openBlock(), _createBlock(_component_bp_label, {
                                        key: 0,
                                        seamless: "",
                                        "label-width": "38.2%",
                                        label: _ctx.$gettext('Apps')
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", null, [
                                            _createTextVNode(_toDisplayString(_ctx.appCount(_ctx.customer)) + " / " + _toDisplayString(_ctx.appStore.loaded ? (_ctx.appStore.apps.additionalApps.length + _ctx.appStore.apps.appPlus.length) : 0) + " ", 1),
                                            _createVNode(_component_translate, null, {
                                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                                _createTextVNode("apps licensed")
                                              ])),
                                              _: 1
                                            })
                                          ])
                                        ]),
                                        _: 1
                                      }, 8, ["label"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_bp_label, {
                                    seamless: "",
                                    "label-width": "38.2%",
                                    label: _ctx.$gettext('Licenses')
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_16, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productLicenses, ([licenseType, licenseCount]) => {
                                          return (_openBlock(), _createElementBlock("span", {
                                            key: `${licenseType}_${licenseCount}`
                                          }, [
                                            (licenseCount < 0)
                                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                  _createVNode(_component_bp_icon, { icon: "infinity" }),
                                                  _createTextVNode(" " + _toDisplayString(licenseType), 1)
                                                ], 64))
                                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                  _createTextVNode(_toDisplayString(licenseCount) + " " + _toDisplayString(licenseType), 1)
                                                ], 64))
                                          ]))
                                        }), 128))
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["label"])
                                ], 64))
                              : _createCommentVNode("", true),
                            (productId === 'bestproxy')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createVNode(_component_bp_label, {
                                    seamless: "",
                                    "label-width": "38.2%",
                                    label: _ctx.$gettext('CPU')
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_translate, {
                                        "translate-n": _ctx.customer.bestproxycores,
                                        "translate-params": { cores: _ctx.customer.bestproxycores },
                                        "translate-plural": "%{cores} cores"
                                      }, {
                                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                                          _createTextVNode("%{cores} core")
                                        ])),
                                        _: 1
                                      }, 8, ["translate-n", "translate-params"])
                                    ]),
                                    _: 1
                                  }, 8, ["label"]),
                                  _createVNode(_component_bp_label, {
                                    seamless: "",
                                    "label-width": "38.2%",
                                    label: _ctx.$gettext('RAM')
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", null, _toDisplayString(_ctx.customer.bestproxyram) + " GB", 1)
                                    ]),
                                    _: 1
                                  }, 8, ["label"]),
                                  (_ctx.customer.webaccess)
                                    ? (_openBlock(), _createBlock(_component_bp_label, {
                                        key: 0,
                                        seamless: "",
                                        "label-width": "38.2%",
                                        label: _ctx.$gettext('Licenses')
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_translate, {
                                            "translate-n": _ctx.customer.bestproxywebaccess,
                                            "translate-params": { webaccesses: _ctx.customer.bestproxywebaccess },
                                            "translate-plural": "%{webaccesses} concurrent web accesses"
                                          }, {
                                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                                              _createTextVNode("%{webaccesses} concurrent web access")
                                            ])),
                                            _: 1
                                          }, 8, ["translate-n", "translate-params"])
                                        ]),
                                        _: 1
                                      }, 8, ["label"]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : _createCommentVNode("", true),
                            (productId === 'bestzero')
                              ? (_openBlock(), _createBlock(_component_bp_label, {
                                  key: 2,
                                  seamless: "",
                                  "label-width": "38.2%",
                                  label: _ctx.$gettext('Licenses')
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_17, [
                                      _createVNode(_component_translate, {
                                        "translate-n": _ctx.customer.numberOfLicences,
                                        "translate-params": { connections: _ctx.customer.numberOfLicences },
                                        "translate-plural": "%{connections} connections"
                                      }, {
                                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                                          _createTextVNode("%{connections} connection")
                                        ])),
                                        _: 1
                                      }, 8, ["translate-n", "translate-params"])
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["label"]))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1032, ["loading"]))
              }), 128)),
              _createVNode(_component_bp_card, { "fill-width": "" }, {
                header: _withCtx(() => [
                  _createVNode(_component_translate, { tag: "h2" }, {
                    default: _withCtx(() => _cache[19] || (_cache[19] = [
                      _createTextVNode("Customer number")
                    ])),
                    _: 1
                  }),
                  _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                    to: { name: 'datamanagement' },
                    class: "ml-auto flex-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_bp_icon, { icon: ['fad', 'address-card'] })
                    ]),
                    _: 1
                  })), [
                    [_directive_tooltip, _ctx.$gettext('Go to datamanagement')]
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.customer.customerNumber), 1),
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode("You can use your customer number to register additional users to your account.")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_bp_card, { "fill-width": "" }, {
                header: _withCtx(() => [
                  _createVNode(_component_translate, { tag: "h2" }, {
                    default: _withCtx(() => _cache[21] || (_cache[21] = [
                      _createTextVNode("Email notifications")
                    ])),
                    _: 1
                  }),
                  _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                    to: { name: 'datamanagement', query: { tab: 'email_notifications' } },
                    class: "ml-auto flex-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_bp_icon, { icon: ['fad', 'address-card'] })
                    ]),
                    _: 1
                  })), [
                    [_directive_tooltip, _ctx.$gettext('Go to datamanagement')]
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[22] || (_cache[22] = [
                          _createTextVNode("Do you want to get notified about new software downloads or videos?")
                        ])),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode("Just subscribe to our email notifications.")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_bp_button, {
                      icon: ['far', 'address-card'],
                      to: { name: 'datamanagement', query: { tab: 'email_notifications' } }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_translate, null, {
                          default: _withCtx(() => _cache[24] || (_cache[24] = [
                            _createTextVNode("Update your preferences")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_bp_card, {
                loading: _ctx.downloadStore.isLoading(),
                "fill-width": ""
              }, {
                header: _withCtx(() => [
                  _createVNode(_component_translate, { tag: "h2" }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode("Downloads")
                    ])),
                    _: 1
                  }),
                  _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                    to: { name: 'download.area' },
                    class: "ml-auto flex-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_bp_icon, { icon: ['fad', 'download'] })
                    ]),
                    _: 1
                  })), [
                    [_directive_tooltip, _ctx.$gettext('Go to download area')]
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_22, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.downloadStore.getFiles('featured'), (file, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: file._id
                      }, [
                        (index !== 0)
                          ? (_openBlock(), _createElementBlock("hr", _hoisted_23))
                          : _createCommentVNode("", true),
                        _createVNode(_component_bp_download_preview, {
                          "model-value": file,
                          disabled: file._disabled
                        }, null, 8, ["model-value", "disabled"])
                      ], 64))
                    }), 128)),
                    (_ctx.downloadStore.getFiles('featured').length === 0)
                      ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                          default: _withCtx(() => _cache[26] || (_cache[26] = [
                            _createTextVNode("Currently there are no recently uploaded files.")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["loading"]),
              (_ctx.hasPlannedWebinar)
                ? (_openBlock(), _createBlock(_component_bp_card, {
                    key: 0,
                    loading: _ctx.videoStore.isLoading(),
                    "fill-width": ""
                  }, {
                    header: _withCtx(() => [
                      _createVNode(_component_translate, { tag: "h2" }, {
                        default: _withCtx(() => _cache[27] || (_cache[27] = [
                          _createTextVNode("Planned webinar")
                        ])),
                        _: 1
                      }),
                      _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                        to: { name: 'video.area' },
                        class: "ml-auto flex-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_icon, { icon: ['fad', 'play-circle'] })
                        ]),
                        _: 1
                      })), [
                        [_directive_tooltip, _ctx.$gettext('Go to video area')]
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_24, [
                        _createVNode(_component_bp_video_preview, { compact: "" })
                      ])
                    ]),
                    _: 1
                  }, 8, ["loading"]))
                : _createCommentVNode("", true),
              (_ctx.videoStore.getVideos({ filter: 'featured' }).length > 0)
                ? (_openBlock(), _createBlock(_component_bp_card, {
                    key: 1,
                    loading: _ctx.videoStore.isLoading(),
                    "fill-width": ""
                  }, {
                    header: _withCtx(() => [
                      _createVNode(_component_translate, { tag: "h2" }, {
                        default: _withCtx(() => _cache[28] || (_cache[28] = [
                          _createTextVNode("Videos & webinar recordings")
                        ])),
                        _: 1
                      }),
                      _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                        to: { name: 'video.area' },
                        class: "ml-auto flex-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_icon, { icon: ['fad', 'play-circle'] })
                        ]),
                        _: 1
                      })), [
                        [_directive_tooltip, _ctx.$gettext('Go to video area')]
                      ])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_25, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoStore.getVideos({ filter: 'featured' }), (recording, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: recording._id,
                            class: "flex-column gap-md"
                          }, [
                            (index !== 0)
                              ? (_openBlock(), _createElementBlock("hr", _hoisted_26))
                              : _createCommentVNode("", true),
                            _createVNode(_component_bp_video_preview, {
                              compact: "",
                              "model-value": recording,
                              disabled: recording._disabled,
                              "hide-webinar-status": ""
                            }, null, 8, ["model-value", "disabled"])
                          ]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }, 8, ["loading"]))
                : _createCommentVNode("", true),
              _createVNode(_component_bp_card, { "fill-width": "" }, {
                header: _withCtx(() => [
                  _createVNode(_component_translate, { tag: "h2" }, {
                    default: _withCtx(() => _cache[29] || (_cache[29] = [
                      _createTextVNode("Need help?")
                    ])),
                    _: 1
                  }),
                  _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                    to: { name: 'support' },
                    class: "ml-auto flex-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_bp_icon, { icon: ['fad', 'ticket'] })
                    ]),
                    _: 1
                  })), [
                    [_directive_tooltip, _ctx.$gettext('Go to support area')]
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode("Do you have problems with your current installation?")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[31] || (_cache[31] = [
                          _createTextVNode("Do you need assistance with updating or installing the software?")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[32] || (_cache[32] = [
                          _createTextVNode("Or do you have technical questions about our products?")
                        ])),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createTextVNode("Just contact our support by opening a new ticket.")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_bp_button, {
                      icon: ['far', 'ticket'],
                      to: { name: 'support' }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_translate, null, {
                          default: _withCtx(() => _cache[34] || (_cache[34] = [
                            _createTextVNode("Open a support ticket")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_bp_card, { "fill-width": "" }, {
                header: _withCtx(() => [
                  _createVNode(_component_translate, { tag: "h2" }, {
                    default: _withCtx(() => _cache[35] || (_cache[35] = [
                      _createTextVNode("Need consultation?")
                    ])),
                    _: 1
                  }),
                  _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                    to: { name: 'consultation' },
                    class: "ml-auto flex-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_bp_icon, { icon: ['fad', 'envelope'] })
                    ]),
                    _: 1
                  })), [
                    [_directive_tooltip, _ctx.$gettext('Go to consultation area')]
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[36] || (_cache[36] = [
                          _createTextVNode("Do you want to extend your current license?")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[37] || (_cache[37] = [
                          _createTextVNode("Do you need a training course for your administrators or users?")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[38] || (_cache[38] = [
                          _createTextVNode("Do you have general questions about our products?")
                        ])),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => _cache[39] || (_cache[39] = [
                        _createTextVNode("Then contact us by requesting a consultation appointment.")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_bp_button, {
                      icon: ['far', 'envelope'],
                      to: { name: 'consultation' }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_translate, null, {
                          default: _withCtx(() => _cache[40] || (_cache[40] = [
                            _createTextVNode("Request a consultation appointment")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })),
          _cache[42] || (_cache[42] = _createElementVNode("hr", null, null, -1)),
          _createVNode(_component_translate, { tag: "h2" }, {
            default: _withCtx(() => _cache[41] || (_cache[41] = [
              _createTextVNode("News")
            ])),
            _: 1
          }),
          (_ctx.newsIsLoading)
            ? (_openBlock(), _createBlock(_component_bp_loading, {
                key: 0,
                "model-value": _ctx.newsIsLoading,
                text: _ctx.$gettext('Loading news…'),
                inline: ""
              }, null, 8, ["model-value", "text"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_31, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news, (newsEntry, index) => {
                  return (_openBlock(), _createElementBlock("blockquote", {
                    key: index,
                    "fill-width": ""
                  }, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("h3", {
                        innerHTML: newsEntry.title
                      }, null, 8, _hoisted_33),
                      _createElementVNode("article", {
                        class: "flex-column gap-sm",
                        innerHTML: newsEntry.text
                      }, null, 8, _hoisted_34)
                    ])
                  ]))
                }), 128))
              ]))
        ])
      ]),
      _: 1
    }, 8, ["loading"])
  ], 4))
}