import { ajax } from '@sahnee/ajax';
import { currentLanguageISOString } from '@/translation';
// import { localizeDate } from './date';
import DOMPurify from 'dompurify';

/**
 * Loads the news articles from `cordaware.com` based on the currently selected language.
 * @returns The news articles.
 */
export async function loadNewsArticles(): Promise<{ title: string, text: string }[]> {
  const url = `/news/${currentLanguageISOString()}`;
  const res = await ajax({
    url,
  });
  const text = await res.text();
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(text, 'text/html'); //.replaceAll('>&nbsp;</', '></'), 'text/html');
  const excerpt = htmlDoc.querySelector('.obfx-grid-container');
  if (!excerpt) {
    return [];
  }
  fixLinks(excerpt);
  fixStyles(excerpt);

  // Sanitize HTML
  const articles: { title: string, text: string }[] = [];

  for (const article of htmlDoc.querySelectorAll('.obfx-grid-container article > div')) {
    for (const element of article.children) {
      if (element.classList.contains('entry-meta')) {
        (element as HTMLElement).outerHTML = '';
      //   const date = localizeDate((element as HTMLElement).innerText.trim()
      //     .replace('Januar', 'January')
      //     .replace('Februar', 'February')
      //     .replace('März', 'March')
      //  // .replace('April', 'April')
      //     .replace('Mai', 'May')
      //     .replace('Juni', 'June')
      //     .replace('Juli', 'July')
      //  // .replace('August', 'August')
      //  // .replace('September', 'September')
      //     .replace('Oktober', 'October')
      //  // .replace('November', 'November')
      //     .replace('Dezember', 'December')
      //   );
      //   (element as HTMLElement).innerHTML = `<span>${date}</span>`;
      }
    }
    const innerHTML = DOMPurify.sanitize(
      article.innerHTML
        .replace(/(?:\t|\n)/g, '')
        .replaceAll('®', '<sup>®</sup>')
        .replaceAll('&reg;', '<sup>&reg;</sup>'),
      { ALLOWED_ATTR: ['target', 'href'], }
    );
    articles.push({ title: innerHTML.replace(/<h2.*?>(.+?)<\/h2>.*/, '$1'), text: innerHTML.replace(/<h2.*?<\/h2>/, '') })/*.replace(/<span>(.*?)<\/span>/, '<svg class="svg-inline--fa fa-calendar fa-fw" aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-v-21503bfd=""><path class="" fill="currentColor" d="M152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192H48V448z"></path></svg><span style="margin-left: var(--padding-sm, 0.5rem);">$1</span>')*/
  }

  return articles;
}

function fixLinks(el: Element) {
  // Fix all hrefs
  for (const href of el.querySelectorAll('[href]')) {
    const current = href.getAttribute('href');
    if (current) {
      href.setAttribute('target', '_blank');
    }
  }
}

function fixStyles(el: Element) {
  // Fix all non-breaking spaces as they should now be simple spaces
  el.innerHTML = el.innerHTML.replaceAll('&nbsp;', ' ');

  // Preserve all italic text
  for (const italic of el.querySelectorAll('[style*="italic"]')) {
    italic.outerHTML = '<em>' + italic.outerHTML + '</em>';
  }

  // Preserve all bold text
  for (const bold of el.querySelectorAll('[style*="bold"]')) {
    bold.outerHTML = '<strong>' + bold.outerHTML + '</strong>';
  }
}